import request from '@/utils/request'
// 获取资讯信息列表-居民端
export function WeGetRealInfoPage(query) {
    return request({
        url: 'RealInfo/WeGetRealInfoPage',
        method: 'get',
        params: query
    })
}
// 获取资讯信息-居民端
export function WeGetRealInfo(query) {
    return request({
        url: 'RealInfo/WeGetRealInfo',
        method: 'get',
        params: query
    })
}
// 小程序-获取社区活动分页列表
export function WeGetActivityPage(query) {
    return request({
        url: 'Activity/WeGetActivityPage',
        method: 'get',
        params: query
    })
}
// 获取社区活动详情
export function WeGetActivityDetail(query) {
    return request({
        url: 'Activity/WeGetActivityDetail',
        method: 'get',
        params: query
    })
}
// 小程序-报名参与社区活动
export function WeEnrolActivity(data) {
    return request({
        url: 'Activity/WeEnrolActivity',
        method: 'post',
        data: data
    })
}
// 小程序获取商铺分页列表
export function WeGetShopPage(query) {
    return request({
        url: 'Shop/WeGetShopPage',
        method: 'get',
        params: query
    })
}
// 获取资讯信息列表-党员端
export function WeGetPartyInfoPage(query) {
    return request({
        url: 'PartyInfo/WeGetPartyInfoPage',
        method: 'get',
        params: query
    })
}
// 获取党员积分分页列表[党员端]
export function WeGetPMIntegralPage(query) {
    return request({
        url: 'Party/WeGetPMIntegralPage',
        method: 'get',
        params: query
    })
}
// 小程序-获取党组活动分页列表
export function WeGetMyActivityPage(query) {
    return request({
        url: 'PBActivity/WeGetMyActivityPage',
        method: 'get',
        params: query
    })
}
// 获取资讯信息-党员端
export function WeGetPartyInfo(query) {
    return request({
        url: 'PartyInfo/WeGetPartyInfo',
        method: 'get',
        params: query
    })
}
// 小程序党建大事件时间轴列表
export function WxGetDateAxisList(query) {
    return request({
        url: 'PartyInfo/WxGetDateAxisList',
        method: 'get',
        params: query
    })
}